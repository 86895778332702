<style lang="scss" scoped></style>

<template>
    <div class="page-device-upgrades">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-upload accept="application/octet-stream" :show-file-list="false"
                        :action="uploadUrl" 
                        :headers="uploadHeaders" 
                        :data="uploadData"
                        :on-success="handleAvatarSuccess"
                        :on-error="handleAvatarError"
                        :before-upload="beforeAvatarUpload">
                        <el-button type="primary" size="medium" icon="el-icon-upload2">添加升级包</el-button>
                    </el-upload>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" border fit highlight-current-row row-key="id">
                <el-table-column prop="name" label="升级包名" width="250"></el-table-column>
                <el-table-column prop="fileUrl" label="升级地址"></el-table-column>
                <el-table-column prop="lastWriteTime" label="上传时间" width="160"></el-table-column>
                <el-table-column label="操作" width="100px" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" plain @click="_upgrade(scope.row)" v-if="isUpdate">选择</el-button>
                        <el-button type="danger" size="mini" plain @click="_delete(scope.row)" v-else>删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import * as funCommon from "@/api/common"
import edit from "./components/TypeEdit"
export default {
    name: "pageDeviceUpgrades",
    props: {
        isUpdate: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tableData: [],
            filter: {
                dir: "Upgrade"
            },
            uploadUrl: "",
            uploadData: {
                dir: "Upgrade",
                fileName: ""
            }
        }
    },
    mounted() {
        // 数据初始化
        this.uploadUrl = this.uploadBaseUrl + "/api/services/app/Shared/UploadFile"
        this.getDataList()
    },
    methods: {
        async getDataList() {
            window.$common.fullLoading()
            funCommon.FileList(this.filter).then(response => {
                window.$common.closeFullLoading()
                if (response && response.length > 0) {
                    this.tableData = response.map(x => {
                        x.name = this.tranceFileName(x)
                        return x
                    })
                } else {
                    this.tableData = []
                }
            }).catch(ex => {
                window.$common.closeFullLoading()
            })
        },
        beforeAvatarUpload(file) {
            if (file.type != "application/octet-stream") {
                this.errorMsg("请上传正确的升级包文件!")
                return false
            }
            this.uploadData.fileName = file.name
            window.$common.fullLoading()
            return true
        },
        handleAvatarSuccess(res, file) {
            this.successMsg("上传成功!")
            this.getDataList()
        },
        handleAvatarError() {
            window.$common.closeFullLoading()
        },
        tranceFileName(row) {
            let name = ""
            let index = row.fileName.lastIndexOf("/")
            name = row.fileName.substr(index + 1)
            return name
        },
        _upgrade(row) {
            this.$emit("selected", row)
        },
        _delete(row) {
            this.confirm(`确认要删除升级包【${row.fileName}】吗？`).then(() => {
                window.$common.fullLoading()
                funCommon.DeleteFile({ fileName: row.fileName }).then(() => {
                    this.successMsg("删除成功!")
                    this.getDataList()
                })
            })
        }
    }
}
</script>