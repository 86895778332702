export default {
    data () {
        return {
            AdPosition: "AdPosition",
            AdType: "AdType",
            AgentLevel: "AgentLevel",
            CategoryType: "CategoryType",
            ClientType: "ClientType",
            DeviceRentTimeUnit: "DeviceRentTimeUnit",
            DeviceStatus: "DeviceStatus",
            DeviceTerminalStatusChangeReason: "DeviceTerminalStatusChangeReason",
            FeedbackStatus: "FeedbackStatus",
            LeaseOrderStatus: "LeaseOrderStatus",
            MemberBalanceChangeReason: "MemberBalanceChangeReason",
            OrderPaymentStatus: "OrderPaymentStatus",
            OrderType: "OrderType",
            PaymentType: "PaymentType",
            ReceiptType: "ReceiptType",
            UserType: "UserType",
            XDeviceInteractorType: "XDeviceInteractorType",
            XDeviceTerminalStatus: "XDeviceTerminalStatus"
        }
    },
    computed: {
        arrAdPosition() {
            // 广告位置
            return  this.getEnumConfigs(this.AdPosition).arrs
        },
        arrAdType() {
            // 广告类型
            return  this.getEnumConfigs(this.AdType).arrs
        },
        arrAgentLevel() {
            // 代理等级
            return  this.getEnumConfigs(this.AgentLevel).arrs
        },
        arrCategoryType() {
            // 分类类型
            return  this.getEnumConfigs(this.CategoryType).arrs
        },
        arrClientType() {
            // 客户端类型
            return  this.getEnumConfigs(this.ClientType).arrs
        },
        arrDeviceRentTimeUnit() {
            // 设备租赁时长单位
            return  this.getEnumConfigs(this.DeviceRentTimeUnit).arrs
        },
        arrDeviceStatus() {
            // 设备状态
            return  this.getEnumConfigs(this.DeviceStatus).arrs
        },
        arrDeviceTerminalStatusChangeReason() {
            // 终端状态改变原因
            return  this.getEnumConfigs(this.DeviceTerminalStatusChangeReason).arrs
        },
        arrFeedbackStatus() {
            // 反馈状态
            return  this.getEnumConfigs(this.FeedbackStatus).arrs
        },
        arrLeaseOrderStatus() {
            // 订单状态
            return  this.getEnumConfigs(this.LeaseOrderStatus).arrs
        },
        arrMemberBalanceChangeReason() {
            // 会员余额变动纪录
            return  this.getEnumConfigs(this.MemberBalanceChangeReason).arrs
        },
        arrOrderPaymentStatus() {
            // 订单支付状态
            return  this.getEnumConfigs(this.OrderPaymentStatus).arrs
        },
        arrOrderType() {
            // 订单类型
            return  this.getEnumConfigs(this.OrderType).arrs
        },
        arrPaymentType() {
            // 支付类型
            return  this.getEnumConfigs(this.PaymentType).arrs
        },
        arrReceiptType() {
            // 收据类型
            return  this.getEnumConfigs(this.ReceiptType).arrs
        },
        arrUserType() {
            // 用户类型
            return  this.getEnumConfigs(this.UserType).arrs
        },
        arrXDeviceInteractorType() {
            return  this.getEnumConfigs(this.XDeviceInteractorType).arrs
        },
        arrXDeviceTerminalStatuse() {
            return  this.getEnumConfigs(this.XDeviceTerminalStatus).arrs
        }
    },
    methods: {
        
    }
}
  
  
  