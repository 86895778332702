import request from '@/utils/request'

// 导入设备
export function DeviceImport(data) {
    return request({
        url: '/api/services/app/Device/Import',
        method: 'post',
        data,
        headers: { "Content-Type": "multipart/form-data" }
    })
}

// (公共)查询设备信息
export function GetDeviceInfo(data) {
    return request({
        url: '/api/services/app/Device/QueryInfo',
        method: 'post',
        data
    })
}

// 生成设备
export function GenerateDevice(data) {
    return request({
        url: '/api/services/app/Device/GenerateDevice',
        method: 'post',
        data
    })
}

// 添加设备返回成功数量
export function AddDevices(data) {
    return request({
        url: '/api/services/app/Device/AddDevices',
        method: 'post',
        data
    })
}

// 删除设备
export function Delete(data) {
    return request({
      url: '/api/services/app/Device/Delete',
      method: 'delete',
      params: data
    })
}

// 更新统计信息，如果不传Id,表示更新所有
export function UpdateDeviceStatistics(data) {
    return request({
      url: '/api/services/app/Device/UpdateStatistics',
      method: 'put',
      data
    })
}

// 获取设备列表
export function GetDeviceList(data) {
    return request({
        url: '/api/services/app/Device/GetPagedDevices',
        method: 'get',
        params: data
    })
}

// 获取设备详情
export function GetDeviceDetail(data) {
    return request({
        url: '/api/services/app/Device/QueryDetail',
        method: 'post',
        data
    })
}

// 设备领取给代理
export function Collect(data) {
    return request({
        url: '/api/services/app/Device/Collect',
        method: 'post',
        data
    })
}

// 设备从代理退回
export function Return(data) {
    return request({
        url: '/api/services/app/Device/Return',
        method: 'post',
        data
    })
}

// 设备铺设到商家
export function Lay(data) {
    return request({
        url: '/api/services/app/Device/Lay',
        method: 'post',
        data
    })
}

// 设备从商家回收
export function Recovery(data) {
    return request({
        url: '/api/services/app/Device/Recovery',
        method: 'post',
        data
    })
}

// 获取设备服务器信息配置
export function QueryServerAddress(data) {
    return request({
        url: '/api/services/app/Device/QueryServerAddress',
        method: 'post',
        data
    })
}

// 获取设备版本信息
export function QueryVersion(data) {
    return request({
        url: '/api/services/app/Device/QueryVersion',
        method: 'post',
        data
    })
}

// 获取设备ICCID
export function QueryICCID(data) {
    return request({
        url: '/api/services/app/Device/QueryICCID',
        method: 'post',
        data
    })
}

// 获取设备网络信息
export function QueryNetworkInfo(data) {
    return request({
        url: '/api/services/app/Device/QueryNetworkInfo',
        method: 'post',
        data
    })
}

// 获取设备库存情况
export function QueryStockInfo(data) {
    return request({
        url: '/api/services/app/Device/QueryStockInfo',
        method: 'post',
        data
    })
}

// 获取设备总库存数量
export function QueryStock(data) {
    return request({
        url: '/api/services/app/Device/QueryStock',
        method: 'post',
        data
    })
}

// 获取设备会话信息
export function QuerySessionInfo(data) {
    return request({
        url: '/api/services/app/Device/QuerySessionInfo',
        method: 'post',
        data
    })
}

// 获取设备信息(完整的库存+数据)
export function QueryDeviceInfo(data) {
    return request({
        url: '/api/services/app/Device/QueryDeviceInfo',
        method: 'post',
        data
    })
}

// 同步设备版本（从设备获取）
export function SyncVersion(data) {
    return request({
        url: '/api/services/app/Device/SyncVersion',
        method: 'post',
        data
    })
}

// 设备ICCID（从设备获取）
export function SyncICCID(data) {
    return request({
        url: '/api/services/app/Device/SyncICCID',
        method: 'post',
        data
    })
}

// 同步设备更新网络信息（从设备获取）
export function SyncNetwork(data) {
    return request({
        url: '/api/services/app/Device/SyncNetwork',
        method: 'post',
        data
    })
}

// 同步服务器地址
export function SyncServerAddress(data) {
    return request({
        url: '/api/services/app/Device/SyncServerAddress',
        method: 'post',
        data
    })
}

// 同步设备库存（从设备获取）
export function SyncStockInfo(data) {
    return request({
        url: '/api/services/app/Device/SyncStockInfo',
        method: 'post',
        data
    })
}

// 切换到测试服
export function SwitchToUAT(data) {
    return request({
        url: '/api/services/app/Device/SwitchToUAT',
        method: 'post',
        data
    })
}

// 切换到正式服
export function SwitchToProd(data) {
    return request({
        url: '/api/services/app/Device/SwitchToProd',
        method: 'post',
        data
    })
}

// 重启报警器
export function Restart(data) {
    return request({
        url: '/api/services/app/Device/Restart',
        method: 'post',
        data
    })
}

// 弹出我的店铺中某个设备中的报警器
export function Eject(data) {
    return request({
        url: '/api/services/app/Device/Eject',
        method: 'post',
        data
    })
}

// 设备升级
export function Upgrade(data) {
    return request({
        url: '/api/services/app/Device/Upgrade',
        method: 'post',
        data
    })
}

// 弹出我的店铺中某个设备中的报警器
export function DeviceTypeCreate(data) {
    return request({
        url: '/api/services/app/DeviceType/Create',
        method: 'post',
        data
    })
}

// 修改设备类型
export function DeviceTypeUpdate(data) {
    return request({
        url: '/api/services/app/DeviceType/Update',
        method: 'put',
        data
    })
}

// 获取所有设备类型（品牌？）
export function GetAllDeviceType(data) {
    return request({
        url: '/api/services/app/DeviceType/GetAll',
        method: 'get',
        params: data
    })
}

// 获取型号列表
export function GetDeviceTypeList(data) {
    return request({
        url: '/api/services/app/DeviceType/GetPagedList',
        method: 'get',
        params: data
    })
}

// 删除设备类型
export function DeviceTypeDelete(data) {
    return request({
        url: '/api/services/app/DeviceType/Delete',
        method: 'delete',
        params: data
    })
}

// 批量删除（不支持）
export function DeviceTypeDeletes(data) {
    return request({
        url: '/api/services/app/DeviceType/Deletes',
        method: 'delete',
        params: data
    })
}

// 获取型号列表
export function GetDeviceTypeDetail(data) {
    return request({
        url: '/api/services/app/DeviceType/Get',
        method: 'get',
        params: data
    })
}

// 获取终端列表
export function GetTerminalList(data) {
    return request({
        url: '/api/services/app/Terminal/GetPagedList',
        method: 'get',
        params: data
    })
}

// 获取终端日志
export function GetTerminalLogs(data) {
    return request({
        url: '/api/services/app/Terminal/GetPagedLogs',
        method: 'get',
        params: data
    })
}

// 删除终端
export function TerminalDelete(data) {
    return request({
      url: '/api/services/app/Terminal/Delete',
      method: 'delete',
      params: data
    })
}