<style scoped lang="scss">
  /deep/ .comp-type-edit {
    max-width: 60%;
  }
</style>

<template>
    <div>
        <el-dialog title="设备型号编辑" custom-class="c-el-dialog comp-type-edit" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="100px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="名称" prop="name">
                            <el-input v-model="dialogData.name" placeholder="请输入名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="交互类型" prop="interactorType">
                            <el-select placeholder="请选择交互类型" v-model="dialogData.interactorType" size="small">
                                <el-option v-for="item in deviceInteractorType" :key="item.value" :label="item.name" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="插槽数" prop="slotCount">
                            <el-input type="number" :min="1" :step="1" v-model="dialogData.slotCount" placeholder="请输入设备插槽数" size="small"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="押金" prop="deposit">
                            <el-input type="number" :min="0.1" :step="0.1" v-model="dialogData.deposit" placeholder="请输入租赁押金" size="small"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="年费" prop="annualFree">
                            <el-input type="number" :min="0.1" :step="0.1" v-model="dialogData.annualFree" placeholder="请输入设备年费" size="small"></el-input>
                        </el-form-item>
                    </el-col>
                    <template v-if="dialogData.rentSettings">
                        <el-col :xs="24">
                            <el-form-item label="起步价" prop="rentSettings.startRent">
                                <el-input class="c-el-input mrgr5" type="number" min="0.1" step="0.1" v-model="dialogData.rentSettings.startRent" placeholder="请输入起步价" size="small"></el-input>
                                <span class="mrgl5 mrgr5">/</span>
                                <el-select class="c-el-input mrgl5" v-model="dialogData.rentSettings.startRentUnit"  placeholder="请选择起步单位" size="small">
                                    <el-option v-for="item in rentTimeUnitOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24">
                            <el-form-item label="租金单价" prop="rentSettings.rentPrice">
                                <el-input class="c-el-input mrgr5" type="number" min="0.1" step="0.1" v-model="dialogData.rentSettings.rentPrice" placeholder="请输入租金单价" size="small"></el-input>
                                <span class="mrgl5 mrgr5">/</span>
                                <el-select class="c-el-input mrgl5" v-model="dialogData.rentSettings.rentTimeUnit"  placeholder="请选择租用单位" size="small">
                                    <el-option v-for="item in rentTimeUnitOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12">
                            <el-form-item label="免费时长" prop="rentSettings.freeMinutes">
                                <el-input type="number" :min="1" :step="1" v-model="dialogData.rentSettings.freeMinutes" placeholder="请输入免费分钟数" size="small">
                                    <template slot="append">分钟</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12">
                            <el-form-item label="日封顶价" prop="rentSettings.maxDayRent">
                                <el-input type="number" :min="0.1" :step="0.1" v-model="dialogData.rentSettings.maxDayRent" placeholder="请输入日封顶费" size="small"></el-input>
                            </el-form-item>
                        </el-col>
                    </template>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="排序" prop="sort">
                            <el-input v-model="dialogData.sort" placeholder="请输入排序" size="small"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="是否激活" prop="isActive">
                            <el-checkbox v-model="dialogData.isActive" size="small">是</el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import configMixins from "@/mixins/config"
import enumConfigs from "@/mixins/enumConfigs"
import * as funDevice from "@/api/device/index"
export default {
    name: "compTypeEdit",
    mixins: [configMixins, enumConfigs],
    data() {
        return {
            showDialog: false,
            dialogType: "",
            defaultData: {
                interactorType: "",
                name: "",
                deposit: "",
                isActive: true,
                rentSettings: {
                    freeMinutes: null,
                    rentTimeUnit: null,
                    rentPrice: null,
                    maxDayRent: null
                },
                slotCount: "",
                annualFree: "",
                sort: 0,
                id: 0
            },
            dialogData: {},
            formRefName: "refDeviceTypeForm",
            formRules: {
                interactorType: [{ required: true, message: "请选择交互类型", trigger: "blur" }],
                name: [{ required: true, message: "请输入名称", trigger: "blur" }],
                sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
                deposit: [{ required: true, message: "请输入租赁押金", trigger: "blur" }],
                "rentSettings.startRent": [{ required: true, message: "请输入起步价", trigger: "blur" }],
                "rentSettings.rentPrice": [{ required: true, message: "请输入租金单价", trigger: "blur" }],
                "rentSettings.maxDayRent": [{ required: true, message: "请输入日封顶费", trigger: "blur" }],
                "rentSettings.freeMinutes": [{ required: true, message: "请输入免费分钟数", trigger: "blur" }],
                slotCount: [{ required: true, message: "请输入设备插槽数", trigger: "blur" }],
                annualFree: [{ required: true, message: "请输入设备年费", trigger: "blur" }]
            },
        }
    },
    computed: {
        deviceInteractorType() {
            return  this.arrXDeviceInteractorType
        },
        rentTimeUnitOptions() {
            return this.arrDeviceRentTimeUnit
        }
    },
    methods: {
        open(row) {
            this.dialogData = JSON.parse(JSON.stringify(this.defaultData))
            this.dialogType = row ? this.createOrUpdate[1] : this.createOrUpdate[0]
            if (this.dialogType == this.createOrUpdate[0]) {
                // 新增
                this.showDialog = true
            } else {
                // 修改
                this.dialogData.id = row.id
                window.$common.fullLoading()
                funDevice.GetDeviceTypeDetail({ id: this.dialogData.id }).then(res => {
                    window.$common.closeFullLoading()
                    for (let i in this.dialogData) {
                        this.dialogData[i] = res[i]
                    }
                    this.showDialog = true
                })
            }
        },
        _save() {
            this.$refs[this.formRefName].validate((valid) => {
                if (valid) {
                    let commitData = JSON.parse(JSON.stringify(this.dialogData))
                    window.$common.fullLoading()
                    if (this.dialogType == this.createOrUpdate[0]){
                        // 创建
                        funDevice.DeviceTypeCreate(commitData).then(() => {
                            this.commonSuccess()
                        })
                    } else {
                        // 更新
                        funDevice.DeviceTypeUpdate(commitData).then(() => {
                            this.commonSuccess()
                        })
                    }
                } else {
                    console.log('error submit!')
                    return false
                }
            })
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val) {
            let tips = val || "操作成功!"
            this.successMsg(tips, {
                onClose: () => {
                    this._close()
                    window.$common.closeFullLoading()
                    this.refreshData()
                }
            })
        },
        refreshData() {
            this.$emit("refreshData", true)
        }
    }
}
</script>